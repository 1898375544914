import React from 'react';
import '../styles.css';

const Coliving = () => (
  <main>
   <section id="hero" className="hero" style={{ backgroundImage: `url(/coliving2.png)` }}>
  <div className="hero-content">
    <h1>COLIVING VERDIZELA, PORTUGAL</h1>
    <p>COLIVING + COWORKING</p>
    <p>
      Coliving Portugal Package Includes: Accommodation | Unlimited Coworking | Sauna, Tennis, Ping Pong + More…
    </p>
    <a href="https://www.airbnb.com/users/show/12662820" className="btn-book-now" target="_blank" rel="noopener noreferrer">Book Now</a>
  </div>
</section>

<section id="horizontal_section" className="section">
  <div className="content">
    <h2 className="section-title">Cosy Accomodation, a home away from home.</h2>
  </div>
</section>


<section id="horizontal_section2" className="section2">
  <div className="content">
    
  </div>
  <div className="coliving-features">
    {features.map((feature, index) => (
      <div className="feature" key={index}>
        <img src={feature.icon} alt={`${feature.title} Icon`} />
        <h3>{feature.title}</h3>
        <p>{feature.description}</p>
      </div>
    ))}
  </div>
</section>

    <section id="accommodation" className="section accommodation-section">
      <div className="content">
        <h2>Rooms & Accommodation</h2>
        <p>
          Our rooms are all made with lots of love! Cosy bedding, convenient layout and lovely decoration will make you feel at home right away.
          You can choose between 2 different accommodation options, ranging from our standard double bed suites to a multi-room appartment. 
          Each standard room is equipped with a private bathroom. The studio includes access to a private balcony, lounge and kitchenette. In all options, guests are provided with free toiletries and beach/swimming pool towels for sunbathing and amazing pool to cool off. 
        </p>
        <div className="centered-image">
          <img src="/airbnb.png" alt="Host Info" />
        </div>
      </div>
      <div className="images">
        <img src="/room4.png" alt="Accommodation 1" />
      </div>
    </section>

    <section id="kitchen" className="section kitchen-section">
      <div className="images">
        <img src="/kitchen.jpg" alt="Kitchen 1" />
      </div>
      <div className="content">
        <h2>Communal Areas</h2>
        <p>
          The communal kitchen includes all the facilities required to cook and you are just a couple of minutes away from a local supermarket. We give extra brownie points for guests that like to cook for everyone.
          The kitchen boasts stunning marble worktops, 6 burner gas stove, large fridge and freezer.
          We also have a nice swimming pool and a lounge room for you to relax or play board games with your new friends. Our place is surf-board friendly too.
        </p>
        <p>
          While we do have amazing staff, please help all guests by washing up and keeping the kitchen area tidy. Our kitchen does have some essentials like free tea & coffee & seasonal fruit.
          We also stock various products, such as Beer, Sparkling Water and Nuts in a separate fridge.
        </p>
      </div>
    </section>

    <section id="who-for" className="section who-for-section">
      <div className="content">
        <h2>Who's it for?</h2>
        <p>
          If you're new to Portugal, then this makes your immersion into the Greater Lisbon/Caparica/Setubal lifestyle fast-tracked and access to the Flamingo Community even faster.
          Enjoy meeting high-calibre fellow entrepreneurs, that value work-life balance, explore the beautiful region of Aroeira & Verdizela, while being a part of a vibrant community of digital nomads, business people,
          participate in weekly events at Flamingo or just lounge around the pool.
        </p>
        <p>
          We designed our Coliving space as your home away from home, where you can work either at your private work space, or in the common areas.
          Our Coworking space is a literal stones throw away from Flamingo Coliving, therefore, you can choose if you want to be using the coworking space to work and the coliving space, to, well… live and enjoy the company of your Coliving neighbours, but it's really up to you.
        </p>
      </div>
      <div className="images">
        <img src="/lounge.jpg" alt="Who for 1" />
      </div>
    </section>

    <section id="location" className="section location-section">
      <div className="images">
        <img src="/location.jpg" alt="Location 1" />
      </div>
      <div className="content">
        <h2>Location</h2>
        <p>
          Get the full coworking and coliving experience and a short drive to the the beach, Flamingo Coliving space has access to everything you need.
        </p>
        <p>
          Flamingo Coliving is nestled in a peaceful road just outside of the Herdade de Aroeira, home to some of Portugal's best golf courses, walking distance from local cafes, a grocery store, a gym, yoga studio & more. 
          The location is amazing and provides access to surrounding areas through scenic routes. We recommend using a scooter, bike or car to get around for true freedom.
        </p>
      </div>
    </section>

    <section id="testimonials" className="section testimonials-section">
      <h2 className="section-title">What our guests say</h2>
      <div className="testimonials">
        {testimonials.map((testimonial, index) => (
          <div className="testimonial" key={index}>
            <p>{testimonial.quote}</p>
            <p><strong>{testimonial.name}</strong> <br/>{testimonial.title}</p>
          </div>
        ))}
      </div>
    </section>

    <section id="gallery" className="section gallery-section">
      <h2 className="section-title">Gallery</h2>
      <p id="horizontal_section">Take a peek inside.</p>
      <div className="gallery-images">
        {galleryImages.map((image, index) => (
          <img src={image.src} alt={`Gallery Image ${index + 1}`} key={index} />
        ))}
      </div>
    </section>
  </main>
);

const features = [
  
 
  { icon: '/icon-room.png', title: 'Cosy Room', description: 'Private Room and Ensuite' },
  { icon: '/icon-cleaning.png', title: 'Cleaning Service', description: 'Cleaning & Maid Service' },
  { icon: '/icon-community.png', title: 'Community', description: 'Amazing Community' },
  { icon: '/icon-workspace.png', title: 'Private Workspace', description: 'Private workspace' },
  { icon: '/icon-yoga.png', title: 'Yoga Lesson', description: 'Group Yoga Session' },
  { icon: '/icon-bathroom.png', title: 'Ensuite Bathroom', description: 'Private Bathroom' },
  { icon: '/icon-coworking.png', title: 'Flamingo Coworking', description: 'Unlimited Access' },
  { icon: '/icon-wifi.png', title: '300Mbps WIFI', description: 'Lightning fast' },
  
];

const testimonials = [
  { quote: 'Staying at Flamingo Coliving was an incredible experience! The community is vibrant and welcoming, and the facilities are top-notch. I loved the private workspace and the events organized by the Flamingo team. Highly recommended for anyone looking to work and relax in Portugal!', name: 'Alex Johnson', title: 'Software Developer, TechCorp' },
  { quote: 'Flamingo Coliving exceeded my expectations. The location is perfect, with easy access to local amenities and beautiful scenery. The room was comfortable and well-decorated, and the communal areas were great for socializing and meeting new people. I\'ll definitely be coming back!', name: 'Maria Gonzalez', title: 'Freelance Designer' },
  { quote: 'I had a fantastic stay at Flamingo Coliving. The yoga and surf lessons were a great addition, and the high-speed internet made it easy to stay connected and productive. The staff were friendly and always willing to help. I highly recommend it to anyone looking for a coliving space in Portugal.', name: 'John Smith', title: 'Entrepreneur' },
];

const galleryImages = [
  { src: '/gallery1.jpg' },
  { src: '/gallery2.jpg' },
  { src: '/gallery3.jpg' },
];

export default Coliving;
