import React from 'react';
import '../styles.css';

const About = () => (
  <main>
    <section id="flamingo_horizontal_section" className="flamingo-section">
      <div className="flamingo-content-wrapper">
        <div className="flamingo-text-content">
          <h2 className="flamingo-section-title">Beautiful coworking & coliving space in Verdizela, Portugal.</h2>
          <p>
            Flamingo is so much more than just a coworking space. It's a community, a melting pot of inspiring individuals from every corner of the globe.
            Flamingo is unique and cosy, our members are diverse, transient and cosmopolitan, and both Flamingo and the Verdizela community benefit greatly from having such a colourful stream of amazing people flowing into our space, connecting and engaging with our members on many different levels.
            We regularly host events with and for community, including masterminds, workshops, community BBQs, skill sharing sessions, networking events, inspirational events and social meetups.
            Our space itself is a beautiful venue a short drive from the world-famous surf breaks of Fonte de Telha and Costa da Caparica. It boasts state-of-the-art facilities, super fast WIFI, an amazing pool surrounded by lush tropical gardens, sauna, tennis, hot showers and a mix of large breezy spaces and nooks for collaborative or individual work.
            If you're feeling peckish onsite, the yummy OVO cafe is just down the road. And when the pressure of work is proving just too much, you can strip off into the sauna, take a dip in the pool or head to the beach for a quick surf.
            Think of Flamingo as a community of like-minded individuals, exchanging skills and knowledge in the pursuit of better quality of life through work-life balance.
          </p>
        </div>
        <div className="flamingo-image-cards">
          <div className="flamingo-card">
            <img src="/umbrella.png" alt="Coliving" />
            <div className="flamingo-card-content">
              <h3>Coliving + Coworking</h3>
              <a href="/coliving" className="flamingo-btn">View Coliving Package</a>
            </div>
          </div>
          <div className="flamingo-card">
            <img src="/sauna.jpg" alt="Blog" />
            <div className="flamingo-card-content">
              <h3>Flamingo Stories</h3>
              <a href="https://www.instagram.com/quinta.flamingos/" className="flamingo-btn">Check Our Insta</a>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="location" className="section location-section">
      <div className="images">
        <img src="/location.jpg" alt="Location 1" />
      </div>
      <div className="content">
        <h2>Our Vision</h2>
        <p>
          Our vision is to create a thriving, collaborative community of conscious co-workers that believe in work-life balance, shared knowledge, productivity, and positive social and environmental change.
        </p>
      </div>
    </section>

    <section id="accommodation" className="section accommodation-section">
      <div className="content">
        <h2>Our Philosophy</h2>
        <p>
          We believe in fostering a collaborative environment where creativity and innovation thrive. Our philosophy is to create a space where individuals can connect, grow, and achieve their goals together.
        </p>
      </div>
      <div className="images">
        <img src="/room4.png" alt="Accommodation 1" />
      </div>
    </section>

    <section id="kitchen" className="section kitchen-section">
      <div className="images">
        <img src="/kitchen.jpg" alt="Kitchen 1" />
      </div>
      <div className="content">
        <h2>Coworking in Portugal</h2>
        <p>
          Flamingos offers state-of-the-art coworking facilities close to the stunning beaches of Fonte de Telha and Costa da Caparica. It's a small curated community with top-notch amenities.
        </p>
      </div>
    </section>

    <section id="who-for" className="section who-for-section">
      <div className="content">
        <h2>Coliving in Portugal</h2>
        <p>
          Our coliving spaces provide the perfect environment for like-minded individuals to live, work, and play together. Join our community and enjoy a unique living experience in Portugal.
        </p>
      </div>
      <div className="images">
        <img src="/lounge.jpg" alt="Who for 1" />
      </div>
    </section>

    <section id="location" className="section location-section">
      <div className="images">
        <img src="/location.jpg" alt="Location 1" />
      </div>
      <div className="content">
        <h2>Our Facilities</h2>
        <p>
          Our facilities include a refreshing salt pool, lush Mediterranean gardens, a sauna, tennis court, ping pong, private booths, hot showers, and various spaces for collaborative or individual work.
        </p>
      </div>
    </section>

    <section id="location" className="section location-section">
      <div className="content">
        <h2>Who's it for</h2>
        <p>
          Flamingos is designed for digital nomads, entrepreneurs, and anyone looking for a supportive and productive coworking and coliving environment. Join our community and thrive together.
        </p>
      </div>
      <div className="images">
        <img src="/lounge.jpg" alt="Location 1" />
      </div>
    </section>
  </main>
);

export default About;