import React from 'react';
import { Link } from 'react-router-dom';
import '../styles.css';

const Footer = () => (
  <footer>
    <p className="left-align">© 2024 Flamingos. All rights reserved.</p>
    <div className="social-links">
      <a href="https://instagram.com/quinta.flamingos" target="_blank" rel="noopener noreferrer">
        <img src="/instagram.png" alt="Instagram" />
      </a>
      <a href="https://www.facebook.com/quintadosflamingos" target="_blank" rel="noopener noreferrer">
        <img src="/facebook.png" alt="Facebook" />
      </a>
    </div>
    <div className="footer-links">
      <Link to="/terms">Terms</Link>
      <Link to="/privacy-policy">Privacy Policy</Link>
    </div>
  </footer>
);

export default Footer;
