import React from 'react';
import '../styles.css';

const Socialimpact = () => {
  return (
    <main>
      <section id="hero-section" className="hero-section" style={{ backgroundImage: `url('/events.png')` }}>
        <div className="hero-content">
          <h1>Social Impact</h1>
          <p>The purpose of life is a life of purpose</p>
        </div>
      </section>

      <section id="vision" className="section location-section">
        <div className="content left-aligned">
          <h2>Work with purpose</h2>
          <p>
            One of the fundamental reasons our founders, Joe Charlesworth and Joana Lemos, started Flamingo was their desire to build a community & business that had purpose behind it. They believe that through creating a community of like-minded, talented individuals there is scope for positive change which can impact the wider global community.
          </p>
        </div>
        <div className="content left-aligned">
          <h2>Get involved!</h2>
          <p>Do you have the time and passion to contribute to the local community? Here’s how you can get involved:</p>
          <ul>
            <li>Think about which skills you can share, and how much time you are prepared to give.</li>
            <li>Look out for Flamingo Community Meetings; these are generally just kept private and posted within the community.</li>
            <li>We can introduce you directly to the organisations that need support.</li>
          </ul>
        </div>
      </section>

      <section id="organisations" className="section organisations-section">
        <h2 className="section-title">Organisations We Support</h2>
        <div className="organisation">
          <img src="/regen.png" alt="Regen Waves Logo" className="organisation-logo"/>
          <div className="organisation-content">
            <h3>Regen Waves</h3>
            <p>Regen Waves focuses on sustainable practices and solutions to regenerate and protect marine environments.</p>
          </div>
        </div>
        <div className="organisation">
          <img src="/ajuda.png" alt="Ajuda de Berço Logo" className="organisation-logo"/>
          <div className="organisation-content">
            <h3>Ajuda de Berço</h3>
            <p>Provides support to children from disadvantaged backgrounds, including residential care and education.</p>
          </div>
        </div>
        <div className="organisation">
          <img src="/Comunidade.png" alt="Comunidade Vida e Paz Logo" className="organisation-logo"/>
          <div className="organisation-content">
            <h3>Comunidade Vida e Paz</h3>
            <p>Works with homeless individuals, providing shelter, food, and support for social reintegration.</p>
          </div>
        </div>
        <div className="organisation">
          <img src="/question.png" alt="Who else Logo" className="organisation-logo"/>
          <div className="organisation-content">
            <h3>Who else should we support?</h3>
            <p>Let us know!</p>
          </div>
        </div>
      </section>

      <section id="giving-banner-bottom" className="banner-section">
        <div className="banner-content">
          <h2>Interested in Giving?</h2>
          <p>
            Are you a member of Flamingo & would you like to give your time, skills, or euros to help one of our Cogiving Partners?
          </p>
          <a href="https://chat.whatsapp.com/E4pjtP07dJp4I7ZyLIawEz" className="btn-signup">I want to help sign me up!</a>
        </div>
      </section>
    </main>
  );
};

export default Socialimpact;
