import React from 'react';
import '../styles.css';

const Terms = () => (
  <main>

<section id="horizontal_section" className="section">
      <div className="content">
        <h2 className="section-title">Terms</h2>
        <p>
          Last updated: Thursday 30th May 2024. 
        </p>
      </div>
      
    </section>


    <section className="terms-section" style={{ padding: '20px', maxWidth: '800px', margin: '0 auto' }}>
    
      
    <div className="terms-content">
        <h3>General Terms (Apply to All Members)</h3>
        <ul>
          <li><strong>Membership Agreement:</strong> Membership at Quinta Flamingos is a binding contract with Joana Lemos, the legal host. We reserve the right to charge introduction fees, annual subscriptions, and admission fees, which may be amended or waived at our sole discretion.</li>
          <li><strong>Trademark Rights:</strong> Unauthorized use of Quinta Flamingos’ names, logos, trademarks, or any identifying features without prior written approval is strictly prohibited.</li>
          <li><strong>Membership Application and Renewal:</strong> Membership applications are reviewed quarterly, and renewals are assessed annually by our Membership Behavior Committee, whose decisions are final.</li>
          <li><strong>Membership Database:</strong> Members agree to provide their personal details and a photograph for our membership database. Please notify us of any changes to your contact or payment information.</li>
          <li><strong>Membership Payment:</strong> Fees are payable immediately upon membership approval or renewal, with failure to pay within one month resulting in potential termination.</li>
          <li><strong>Resignation:</strong> Resigning or cancelled members may still be liable for the remainder of the year's membership fees.</li>
          <li><strong>Guests:</strong> Members can bring up to three guests, subject to restrictions during busy periods. Members are responsible for their guests' adherence to Quinta rules.</li>
          <li><strong>Children and Service Animals:</strong> Quinta Flamingos is child-friendly but designed primarily for adults. Only legally recognized service animals are allowed.</li>
          <li><strong>Payments:</strong> All bills must be settled in full before departure.</li>
          <li><strong>Environment and Conduct:</strong> Members and guests should maintain a casual and relaxed atmosphere and respect privacy and quietness. Mobile device and laptop use are limited to designated areas.</li>
          <li><strong>Prohibitions:</strong> Smoking indoors, use of illegal substances, and carrying firearms or weapons are strictly prohibited.</li>
          <li><strong>Recording and Media:</strong> No recording devices without approval. Audio and video must be used with headphones.</li>
          <li><strong>Private Hire and Maintenance:</strong> The Quinta may close areas for private events or maintenance without notice, with possible compensation.</li>
          <li><strong>Facilities Use:</strong> Facilities like the gym, sauna, and pool are used at one's own risk. Introduction sessions are provided.</li>
          <li><strong>Entry and Exit:</strong> Please be considerate of noise and driving behavior in surrounding areas.</li>
          <li><strong>Disciplinary Actions:</strong> Inappropriate conduct may lead to suspension or expulsion.</li>
          <li><strong>Non-Disclosure:</strong> Confidentiality regarding other members and events must be maintained.</li>
          <li><strong>Amendments:</strong> We reserve the right to amend policies at our discretion.</li>
          <li><strong>Personal Information and Privacy:</strong> Personal information is managed in accordance with our Privacy Policy.</li>
          <li><strong>Event Participation:</strong> Participation in events implies consent to use your name and image for promotional purposes.</li>
          <li><strong>Accidents and Injuries:</strong> Accidents must be reported within 24 hours. We are not liable for injuries or losses except as legally required.</li>
          <li><strong>CCTV:</strong> CCTV is operational for security purposes; footage is released only by legal requirement.</li>
          <li><strong>Room Bookings and Discounts:</strong> Members receive a 20% discount on room bookings, applicable also to guest referrals.</li>
          <li><strong>Access for Non-Members:</strong> We may allow non-members to access the premises at our discretion.</li>
        </ul>

        <h3>Cowork Members</h3>
        <ul>
          <li><strong>Cowork Access:</strong> Free and unlimited access to cowork spaces during the membership period.</li>
          <li><strong>Room Booking Discount:</strong> A 10% discount on room bookings at the Coliving for themselves or members of their professional network, subject to availability.</li>
        </ul>

        <h3>Coliving Members</h3>
        <ul>
          <li><strong>Room Allocation Flexibility:</strong> We reserve the right to move guests to an alternative room of similar standard if necessary.</li>
          <li><strong>Unlimited Cowork Access:</strong> Free and unlimited access to cowork spaces is included with your stay.</li>
        </ul>

        <h3>Private Events/Team Retreats</h3>
        <ul>
          <li><strong>Booking Conditions:</strong> Reservations are confirmed upon signing the terms, which include COVID-19 protocols and identification requirements.</li>
          <li><strong>Deposits and Payments:</strong> A non-refundable deposit of 25% is required, with full payment due 60 days prior to the event.</li>
          <li><strong>Cancellations and Date Changes:</strong> Cancellations within 60 days incur full charges; changes may involve additional costs.</li>
          <li><strong>Accommodation and Capacity:</strong> Event capacities and arrangements must be finalized 60 days prior.</li>
          <li><strong>Damage Responsibility:</strong> Guests are liable for damages caused during the event.</li>
          <li><strong>Catering and Suppliers:</strong> Specific terms apply to catering and third-party suppliers.</li>
          <li><strong>Insurance and Liability:</strong> Adequate insurance coverage is recommended as Quinta Flamingos disclaims liability for personal accidents or losses.</li>
        </ul>
      </div>
    </section>
  </main>
);

export default Terms;