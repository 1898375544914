import React, { useState } from 'react';
import '../styles.css';
import { Link } from 'react-router-dom';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header>
      <div className="logo">
        <Link to="/">
          <img src="/flamingo_white.png" alt="Flamingos Logo" style={{ height: '50px' }} />
        </Link>
      </div>
      <nav>
        <ul className={`nav-links ${isMenuOpen ? 'nav-active' : ''}`}>
          <li><Link to="/">Home</Link></li>
          <li><Link to="/about">About</Link></li>
          <li><Link to="/socialimpact">Social Impact</Link></li>
          <li><Link to="/coliving">Coliving</Link></li>
          <li><Link to="/checkout">Prices</Link></li>
          <li><Link to="/contact">Contact</Link></li>
        </ul>
        <div className="burger" onClick={toggleMenu}>
          <div className={`line1 ${isMenuOpen ? 'toggle' : ''}`}></div>
          <div className={`line2 ${isMenuOpen ? 'toggle' : ''}`}></div>
          <div className={`line3 ${isMenuOpen ? 'toggle' : ''}`}></div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
