import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../styles.css';

const Home = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = ["cowork.png", "pool.png", "lounge.png"];

  const handleNext = () => {
    setCurrentImageIndex((currentImageIndex + 1) % images.length);
  };

  const handlePrev = () => {
    setCurrentImageIndex((currentImageIndex - 1 + images.length) % images.length);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      handleNext();
    }, 3000); // Change image every 3 seconds

    return () => clearInterval(interval); // Clean up on component unmount
  }, [currentImageIndex]);

  return (
    <main>
      <section id="hero">
        <div className="carousel">
          <div className="images">
            <img src={images[currentImageIndex]} alt="Carousel" />
            <div className="overlay-text">
              <h1>Welcome to Flamingos</h1>
              <h2>COMMUNITY</h2>
              <h2>COWORKING</h2>
              <h2>COLIVING</h2>
            </div>
          </div>
          <div className="buttons">
            <button id="prev" onClick={handlePrev}>&lt;</button>
            <button id="next" onClick={handleNext}>&gt;</button>
          </div>
        </div>
      </section>

      <section id="horizontal_section" className="section">
      <div className="content">
      
        <p>
        Flamingos is a coworking space in Verdizela, Portugal located just a few minutes drive from the wonderful beaches of Fonte de Telha and Caparica. Our vision is to create a thriving, collaborative community of conscious co-workers that believe in work-life balance, shared knowledge, productivity and positive social and environmental change.
        </p>
      </div>
    </section>


      <section id="cards">
        <div className="card-container">
          <div className="card">
            <h3>About Us</h3>
            <img src="about.png" alt="About Us" />
            <p>Find out about Flamingos and the facilities.</p>
            <Link to="/about" className="btn">More</Link>
          </div>
          <div className="card">
            <h3>Social Impact</h3>
            <img src="impact.png" alt="Events" />
            <p>Discover our social impact initiatives. </p>
            <a href="/socialimpact" className="btn">More</a>
          </div>
          <div className="card">
            <h3>Pricing</h3>
            <img src="prices.png" alt="Pricing" />
            <p>Discover our flexible membership plans.</p>
            <a href="checkout" className="btn">More</a>
          </div>
          <div className="card">
            <h3>Coliving</h3>
            <img src="coliving.png" alt="Coliving" />
            <p>Find out more about our coliving.</p>
            <a href="/coliving" className="btn">More</a>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Home;
