import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Socialimpact from './pages/Socialimpact';
import Coliving from './pages/Coliving';
import Contact from './pages/Contact';
import SubscriptionSuccess from './pages/SubscriptionSuccess';
import SubscriptionCheckout from './pages/SubscriptionCheckout';
import SubscriptionCancel from './pages/SubscriptionCancel';
import WelcomeTour from './pages/WelcomeTour';
import Terms from './pages/Terms';
import PrivacyPolicy from './pages/PrivacyPolicy'; // Import the Privacy Policy component
import './styles.css';

const App = () => (
  <Router>
    <Header />
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/socialimpact" element={<Socialimpact />} />
      <Route path="/coliving" element={<Coliving />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/terms" element={<Terms />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} /> {/* Add the Privacy Policy route */}
      <Route path="/success" element={<SubscriptionSuccess />} />
      <Route path="/checkout" element={<SubscriptionCheckout />} />
      <Route path="/cancel" element={<SubscriptionCancel />} />
      <Route path="/welcome-tour" element={<WelcomeTour />} />
    </Routes>
    <Footer />
  </Router>
);

export default App;
