import React from 'react';
import '../styles.css';
import { Link } from 'react-router-dom';

const SubscriptionCheckout = () => {
  const plans = [
    {
      id: '1',
      name: '1 Day per Week',
      description: 'Enjoy coworking one day per week.',
      price: '43 EUR per month',
      currency: 'EUR',
      image: 'icon-workspace.png',
      stripeLink: 'https://buy.stripe.com/00g7uS1Zcd6K0JqeUW',
    },
    {
      id: '2',
      name: 'Day Pass Pack',
      description: 'Pack of awesome 5 day passes.',
      price: '75 EUR',
      currency: 'EUR',
      image: 'icon-workspace.png',
      stripeLink: 'https://buy.stripe.com/28o16u6fs3wa3VC9AA',
    },
  ];

  const handleSubscribe = (stripeLink) => {
    window.location.href = stripeLink;
  };

  return (
    <main>
      <section id="hero-section" className="hero-section" style={{ backgroundImage: `url('/about.png')` }}>
        <div className="hero-content">
          <h1>Check Out Our Plans</h1>
          <p>Choose a plan that suits your needs.</p>
        </div>
      </section>

      <section id="cards">
        <div className="card-container">
          {plans.map((plan) => (
            <div key={plan.id} className="card">
              <h3>{plan.name}</h3>
              <img src={plan.image} alt={plan.name} />
              <p>{plan.description}</p>
              <p>{plan.price}</p>
              <button className="btn" onClick={() => handleSubscribe(plan.stripeLink)}>
                Select Plan
              </button>
            </div>
          ))}
        </div>
      </section>
    </main>
  );
};

export default SubscriptionCheckout;