import React from 'react';
import { Link } from 'react-router-dom';

const SubscriptionCancel = () => {
  return (
    <div className="subscription-cancel">
      <h1>Subscription Canceled</h1>
      <p>Your subscription has been canceled. If you have any questions or need assistance, please contact our support team.</p>
      <Link to="/pricing" className="button">
        Explore Other Plans
      </Link>
      <Link to="/" className="button">
        Return to Home
      </Link>
    </div>
  );
};

export default SubscriptionCancel;