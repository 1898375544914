import React from 'react';
import { Link } from 'react-router-dom';
import '../styles.css';

const SubscriptionSuccess = () => {
  return (
    <div className="subscription-success">
      <h1>Subscription Successful!</h1>
      <p>Thank you for subscribing to our service. Your subscription is now active.</p>
      <p>You can access your account and start enjoying the benefits of your subscription.</p>
      <Link to="/account" className="button">
        Go to My Account
      </Link>
    </div>
  );
};

export default SubscriptionSuccess;
