import React from 'react';
import '../styles.css';

const Contact = () => (
  <main>
    
    <section id="horizontal_section" className="section">
      <div className="content">
        <h2 className="section-title">Contact Us</h2>
        <p>
        We would love to hear from you! Whether you have feedback, want to make a booking, are interested in private events, or are planning a team retreat, we are here to help.
        </p>
      </div>
    </section>

    <section id="contact" style={{ padding: '20px', textAlign: 'center', maxWidth: '800px', margin: '0 auto' }}>
      <a
        href="https://wa.me/+351933255343"
        target="_blank"
        rel="noopener noreferrer"
        className="whatsapp-button"
        style={{
          display: 'inline-block',
          backgroundColor: '#25D366',
          color: 'white',
          padding: '10px 20px',
          borderRadius: '5px',
          textDecoration: 'none',
          margin: '20px 0',
          fontSize: '1.2em'
        }}
      >
        
        Contact us on WhatsApp
      </a>
    </section>

    <div className="address-container" style={{ padding: '20px', textAlign: 'center', maxWidth: '800px', margin: '0 auto', fontSize: '1.2em', color: '#333', fontWeight: 'bold' }}>
      Address: 35 Rua Reserva Natural Serra da Malcata, Corroios, 2855-641
    </div>

    <div className="map-container" style={{ width: '100%', marginTop: '20px' }}>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d49906.081319845725!2d-9.189555708623955!3d38.57685215412776!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd194b0078204d63%3A0xae2fee7a9638c8f1!2sFlamingos%20CoWork!5e0!3m2!1spt-PT!2spt!4v1717060368392!5m2!1spt-PT!2spt"
        width="100%"
        height="450"
        frameBorder="0"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        title="Map"
      ></iframe>
    </div>
  </main>
);

export default Contact;
