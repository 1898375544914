import React from 'react';
import '../styles.css';

const WelcomeTour = () => (
  <main>
    <section id="hero-section" className="hero-section" style={{ backgroundImage: `url('/cowork.png')` }}>
        <div className="hero-content">
          <h1>Welcome aboard</h1>
          <p>Now let's you get settled in!</p>
        </div>
      </section>

    <section id="meeting-scheduling" className="meeting-scheduling-section">
      <div className="content">
        <h2>Schedule Your Welcome Tour</h2>
        <p>Select a convenient time for your welcome tour and get familiar with our community and amenities.</p>
      </div>
      <iframe src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ0SQ34qJSWermNOmJRW0-Tv3wRBn0R2KiX9GgApZfU3BuyiTCdY2c76u236ctBGpWVMzgc0D8zr?gv=true"></iframe>
    </section>
  </main>
);

export default WelcomeTour;
